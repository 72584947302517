import React, { ReactElement, useCallback, useState } from 'react';
import { Toast, UiTable } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityProductGroup } from 'module/purchase';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	SelectProductsFilter,
	TSelectProductFilter,
} from 'module/purchase/components/selectProducts/SelectProductsFilter';
import { useFormikContext } from 'formik';
import { IGenerateRetailCodesForm } from 'module/retail';
import { useRetailProductGroupList } from 'module/retail/hooks/useRetailProductGroupList';
import {
	SelectProductVariantModal,
	TSelectProductVariantModalProps,
} from 'js/components/molecules/Modal/SelectProductVariantModal';
import { toast } from 'react-toastify';
import { retailConfig } from 'module/retail/retailConfig';
import { extractProductGroupCode } from 'module/purchase/utils/common';
import { useGenerateRetailCodesMarketSegments, useSelectProductsColumns } from 'module/retail/hooks';
import { filterData } from 'js/utils/common';
import { normalizeVariants } from 'js/utils/normalize';
import { STATUS_SUCCESS } from 'appConstants';
import { TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { isGroupConsumer } from 'module/purchase/utils/selectors';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { ISelectProductVariantTableProps } from 'js/components/table/selectProductVariant/SelectProductVariantTable';

const STATUS_SELECT_VARIANT_CLOSE = 2;

type TTableData = IEntityProductGroup;
type TSelectProductsProps = {
	onAddItem: (close: boolean) => void;
};
type TProductVariantTableProps = Pick<ISelectProductVariantTableProps, 'productGroup'>;

export const SelectProducts = (props: TSelectProductsProps): ReactElement => {
	const { onAddItem } = props;
	const {
		values: { partner },
		setValues,
	} = useFormikContext<IGenerateRetailCodesForm>();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const [filter, setFilter] = useState<TSelectProductFilter>({
		searchValue: '',
		entity: {},
	});
	const selectVariantRef = useAsyncModalRef<TProductVariantTableProps>();

	// Get groups
	const { data: productGroups, query } = useRetailProductGroupList(partner);
	const marketSegments = useGenerateRetailCodesMarketSegments();

	// On Select product group action
	const onSelectProductGroup = useCallback(
		async (productGroup: IEntityProductGroup) => {
			const response = await selectVariantRef.current?.show({ productGroup });
			if (response) {
				onAddItem(response === STATUS_SELECT_VARIANT_CLOSE);
			}
		},
		[selectVariantRef, onAddItem],
	);

	// Columns
	const columns = useSelectProductsColumns(onSelectProductGroup);

	const submitHandler: TSelectProductVariantModalProps['onSubmit'] = (values, options = {}) => {
		const items = normalizeVariants(values);
		const { setSubmitting } = options.formikHelpers ?? {};
		setSubmitting?.(false);

		if (items.length !== 0) {
			setValues((values) => ({
				...values,
				products: [
					...values.products,
					...items.map((item) => ({
						quantity: item.quantity!,
						unit: item.unit || 0,
						product: item.product,
					})),
				],
			}));
			toast.success(<Toast>{t(retailConfig.trPrefix('generate.products.success'))}</Toast>);
			selectVariantRef.current?.onSuccess(options.closeOnSubmit ? STATUS_SELECT_VARIANT_CLOSE : STATUS_SUCCESS);
		}
	};

	const getInitialState = (productGroup: IEntityProductGroup): TSelectVariantItem[] => {
		return productGroup.products.map((product, i) => ({
			id: i,
			checked: false,
			quantity: isGroupConsumer(productGroup) ? 0 : 1,
			unit: isGroupConsumer(productGroup) ? product.bulkQuantity : 0,
			product: orderInstanceNormalizer.normalizeProduct(productGroup, product),
		}));
	};

	return (
		<>
			<SelectProductsFilter
				filter={filter}
				onChange={setFilter}
				marketSegments={marketSegments}
			/>

			<UiTable<TTableData>
				testId="products"
				data={filterData<TTableData>(productGroups, filter.entity)}
				columns={columns}
				enableSorting
				initialState={{ sorting: [{ id: 'name', desc: false }] }}
				state={{ globalFilter: extractProductGroupCode(filter.searchValue) || filter.searchValue }}
				onGlobalFilterChange={(searchValue: string) => setFilter((values) => ({ ...values, searchValue }))}
				meta={{
					customError: query.isError,
					isScrollable: true,
					loading: query.isFetching,
					loadingType: 'BAR',
					onRowClick: onSelectProductGroup,
				}}
			/>

			<AsyncModal
				backdrop="static"
				ref={selectVariantRef}
				testId="selectVariant"
			>
				{({ productGroup }) => (
					<SelectProductVariantModal
						productGroup={productGroup}
						onSubmit={submitHandler}
						initialState={getInitialState(productGroup)}
						module="retail"
					/>
				)}
			</AsyncModal>
		</>
	);
};

import { Modal, Toast } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { toast } from 'react-toastify';
import { updateCustomerApiError } from 'module/customers/utils/apiError';
import { useApiUpdateCustomer } from 'module/customers/hooks/useApiCustomers';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityCustomer } from 'module/customers';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { TPartnerId } from 'types';

type TAsyncUpdateCustomerModalProps = {
	onUpdate: (customer: IEntityCustomer) => void;
	customer?: IEntityCustomer | null;
	partnerId?: TPartnerId | null;
};

export const AsyncUpdateCustomerModal = (props: TAsyncUpdateCustomerModalProps & TAsyncModalContainerProps) => {
	const { onUpdate, customer, forwardedRef } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const { authCompanyId, isGroupPartner } = useAuthContext();
	const { setError } = useApiErrorContext();
	const partnerId = isGroupPartner ? authCompanyId : props.partnerId;
	const { mutateAsync: updateCustomer } = useApiUpdateCustomer();

	if (!customer || !partnerId) {
		forwardedRef.current?.onCancel();
		return null;
	}

	return (
		<>
			<Modal.Header>{getCustomerRenderName(customer)}</Modal.Header>
			<Modal.Body>
				<CustomerForm
					initialValues={customer}
					partnerId={partnerId}
					isUpdate
					onSubmit={async (request, { setSubmitting }) => {
						const response = await updateCustomer({
							data: request.customer,
							params: { partnerId: request.partnerId, salesforceCustomerId: customer?.id! },
						}).catch((error) => {
							setError({ error, resolve: updateCustomerApiError });
						});

						setSubmitting(false);

						if (response?.data) {
							toast.success(<Toast>{t('page.update.success')}</Toast>);
							onUpdate(response.data);
						}
					}}
					onCancel={() => {
						forwardedRef.current?.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};

import React, { ReactElement, useCallback, useState } from 'react';
import { Button, ButtonComposition, Card, CardBody, CardHeader, IconButton, Tooltip } from '@avast/react-ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { IconChange, IconClose } from 'assets/image/icon';
import { AsyncUpdateCustomerModal, CustomerCard, SelectCustomerCurrencyModal } from 'module/customers/components';
import { SelectCardState } from 'module/purchase/components/widget';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { BillablePartyText } from 'module/purchase/components/widget/BillablePartyText';
import { EndCustomerCurrency } from 'module/purchase/components/common/EndCustomerCurrency';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { customersConfig } from 'module/customers/customersConfig';
import { Can } from 'js/components/molecules/Can';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useCommonContext, useOrderContext } from 'js/contexts';

const widgetTrPrefix = 'widget.selectCustomerCard';

export const SelectCustomerCard = (): ReactElement => {
	const { createCustomerRef } = useCommonContext();
	const updateRef = useAsyncModalRef();

	// Hooks
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { isGroupPartner } = useAuthContext();
	const [loading, setLoading] = useState(false);
	const [currencyModal, setCurrencyModal] = useState(false);
	const { setCustomer, setCustomerCurrencyCode, orderState, isEndCustomerBillableParty, hasBillableParty } =
		useOrderContext();
	const { selectCustomerRef } = useCommonContext();

	// Data
	const customer = orderState?.customer;
	const customerId = customer?.id;
	const partnerId = orderState?.partner?.id;

	const selectCustomer = useCallback(async () => {
		setLoading(true);
		const selectedCustomer = await selectCustomerRef.current?.show({
			partnerId,
			selectedCustomerId: customerId,
			enableCreate: true,
		});

		// Nothing selected
		if (!selectedCustomer?.id) {
			setLoading(false);
			return;
		}

		// Add customer to the order
		setCustomer(selectedCustomer);

		// Customer became billable party
		if (!hasBillableParty || isEndCustomerBillableParty) {
			selectedCustomer?.currency ? setCustomerCurrencyCode(selectedCustomer?.currency) : setCurrencyModal(true);
		}

		setLoading(false);
	}, [
		setCustomer,
		selectCustomerRef,
		partnerId,
		customerId,
		hasBillableParty,
		isEndCustomerBillableParty,
		setCustomerCurrencyCode,
	]);

	return (
		<Card active={isEndCustomerBillableParty}>
			<CardHeader>{t(`${widgetTrPrefix}.title`)}</CardHeader>
			<CardBody>
				{(() => {
					if (loading) {
						return <LoadingPlaceholder />;
					}

					if (customer) {
						return (
							<div className="state-result">
								<ButtonComposition
									size="xs"
									className="justify-content-end"
								>
									<Tooltip
										content={t(`${widgetTrPrefix}.tooltips.change`)}
										placement="bottom"
									>
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconChange />}
											onClick={selectCustomer}
											testId="changeCustomer"
										/>
									</Tooltip>
									<Can
										do={customersConfig.aclModule}
										update
									>
										<Tooltip
											content={t(`${widgetTrPrefix}.tooltips.update`)}
											placement="bottom"
										>
											<IconButton
												variant="outline-primary"
												size="xs"
												iconFa={faPencilAlt}
												onClick={() => updateRef.current?.show()}
												testId="updateCustomer"
											/>
										</Tooltip>
									</Can>
									<Tooltip
										content={t(`${widgetTrPrefix}.tooltips.cancel`)}
										placement="bottom"
									>
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconClose />}
											onClick={() => setCustomer(null)}
											testId="unsetCustomer"
										/>
									</Tooltip>
								</ButtonComposition>
								<div className="mt-3 content">
									<CustomerCard customer={customer} />
									{isEndCustomerBillableParty && (
										<EndCustomerCurrency
											currencyCode={orderState.customerCurrencyCode}
											onChange={() => setCurrencyModal(true)}
										/>
									)}
								</div>
								{!isGroupPartner && (
									<BillablePartyText
										isBillable={isEndCustomerBillableParty}
										className="mt-3"
									/>
								)}
								{isEndCustomerBillableParty && (
									<SelectCustomerCurrencyModal
										show={currencyModal}
										onCancel={() => {
											setCurrencyModal(false);
											if (!orderState.customerCurrencyCode) {
												setCustomer(null);
											}
										}}
										value={orderState.customerCurrencyCode}
										onSelect={(currencyCode) => {
											setCurrencyModal(false);
											setCustomerCurrencyCode(currencyCode);
										}}
										countryCode={customer.billing.countryCode}
									/>
								)}
							</div>
						);
					}

					// No value
					return (
						<SelectCardState
							note={<Trans t={t}>{`${widgetTrPrefix}.note`}</Trans>}
							isBillable={!hasBillableParty || isEndCustomerBillableParty}
						>
							<p>
								<Button
									variant="outline-primary"
									size="sm"
									onClick={selectCustomer}
									testId="selectCustomer"
								>
									{t(`${widgetTrPrefix}.select`)}
								</Button>
							</p>
							<Can
								do={customersConfig.aclModule}
								create
							>
								<p>
									<Button
										variant="light"
										size="sm"
										onClick={async () => {
											const customer = await createCustomerRef.current?.show({
												partnerId: orderState.partner?.id,
											});
											if (customer) {
												setCustomer(customer);
											}
										}}
										testId="createCustomer"
									>
										{t(`${widgetTrPrefix}.createNew`)}
									</Button>
								</p>
							</Can>
						</SelectCardState>
					);
				})()}

				<AsyncModal
					ref={updateRef}
					testId="updateCustomer"
					backdrop="static"
				>
					<AsyncUpdateCustomerModal
						forwardedRef={updateRef}
						customer={orderState.customer}
						partnerId={orderState.partner?.id}
						onUpdate={(customer) => {
							setCustomer(customer);
							updateRef.current?.hide();
						}}
					/>
				</AsyncModal>
			</CardBody>
		</Card>
	);
};

import { toString } from 'lodash';

export enum CustomEventEnum {
	CLOSE_MODALS = 'CLOSE_MODALS',
}

export const customEvent = {
	subscribe(name: CustomEventEnum, listener: EventListener) {
		document.addEventListener(toString(name), listener);
	},
	unsubscribe(name: CustomEventEnum, listener: EventListener) {
		document.removeEventListener(toString(name), listener);
	},
	trigger(name: CustomEventEnum, data?: unknown) {
		const event = new CustomEvent(toString(name), { detail: data });
		document.dispatchEvent(event);
	},
};

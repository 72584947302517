import React from 'react';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { useApiAcceptTerms } from 'module/registration/hooks/useApiRegistration';
import { resolveTermsAcceptedApiError } from 'module/registration/utils/apiError';
import { TermsForm } from 'module/registration/forms/TermsForm';
import { RegistrationStepEnum } from 'module/registration/enums';
import { useApiErrorContext } from 'js/contexts';

export const Terms = () => {
	const { setError } = useApiErrorContext();
	const { setStep, partnerId } = useRegistrationContext();
	const { mutate: acceptTerms } = useApiAcceptTerms({
		config: { params: { partnerId } },
	});

	return (
		<TermsForm
			partnerId={partnerId}
			onSubmit={async (_, { setSubmitting }) => {
				acceptTerms(
					{},
					{
						onSuccess() {
							setStep(RegistrationStepEnum.REGISTRATION_INFO);
						},
						onError(error) {
							setError({ error, resolve: resolveTermsAcceptedApiError });
							setSubmitting(false);
						},
					},
				);
			}}
		/>
	);
};

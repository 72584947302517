import { filterPostValues } from 'js/utils/form';
import { isCustomerConsumer } from 'module/customers/utils/customerSelectors';
import { ICustomerForm } from 'module/customers/index';

export const customerFormNormalizer = {
	denormalize(values: ICustomerForm): ICustomerForm {
		const result = filterPostValues(values);
		delete result?.partnerId;
		delete result?.currency;
		if (isCustomerConsumer(result)) {
			delete result?.companyName;
			delete result?.requestedDiscountType;
			delete result?.industry;
		}
		return result;
	},
};

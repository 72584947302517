import React, { ReactElement, useMemo } from 'react';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { IApiCustomerFilter, IApiPartnerCustomerFilter, IEntityCustomer } from 'module/customers';
import { DetailContent } from 'module/customers/components';
import { customersConfig } from 'module/customers/customersConfig';
import { useApiCustomer, useApiPartnerCustomer } from 'module/customers/hooks/useApiCustomers';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { useAuthContext } from 'js/contexts';
import { useCustomerUrlParams, useGetCustomerLink } from 'module/customers/hooks';

type TData = IEntityCustomer;
type TQueryProps = TUseApiDetailProps<TData, IApiCustomerFilter>;
type TPartnerQueryProps = TUseApiDetailProps<TData, IApiPartnerCustomerFilter>;

export const PageDetail = (): ReactElement => {
	const { id, partnerId: urlPartnerId } = useCustomerUrlParams();
	const { authCompanyId, isGroupPartner } = useAuthContext();
	const getCustomerLink = useGetCustomerLink();

	/**
	 * Setup query props/filter
	 * @type {TPartnerQueryProps | TQueryProps}
	 */
	const queryProps: TPartnerQueryProps | TQueryProps = useMemo(() => {
		if (isGroupPartner) {
			return {
				filter: {
					partnerId: authCompanyId,
					salesforceId: id,
				},
			};
		}

		if (urlPartnerId) {
			return {
				filter: {
					partnerId: urlPartnerId,
					salesforceId: id,
				},
			};
		}

		return { filter: { salesforceId: id } };
	}, [id, authCompanyId, isGroupPartner, urlPartnerId]);

	if (isGroupPartner || urlPartnerId) {
		return (
			<PageDetailComponent<TData, IApiPartnerCustomerFilter>
				query={useApiPartnerCustomer}
				queryProps={queryProps as TPartnerQueryProps}
				trNamespace={customersConfig.trNamespace}
				titleRender={getCustomerRenderName}
				detailLink={getCustomerLink.detail(id, urlPartnerId)}
			>
				<DetailContent partnerId={urlPartnerId} />
			</PageDetailComponent>
		);
	}

	return (
		<PageDetailComponent<TData, IApiCustomerFilter>
			query={useApiCustomer}
			queryProps={queryProps as TQueryProps}
			trNamespace={customersConfig.trNamespace}
			titleRender={getCustomerRenderName}
			detailLink={getCustomerLink.detail(id)}
		>
			<DetailContent />
		</PageDetailComponent>
	);
};

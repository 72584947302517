import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { customersConfig } from 'module/customers/customersConfig';
import { PartnerCustomerRoutes } from 'module/customers/PartnerCustomerRoutes';

export const PartnerCustomersContainer: FunctionComponent = () => (
	<DefaultContainer>
		<ModuleContainer moduleMenuConfig={customersConfig} />
		<PartnerCustomerRoutes />
	</DefaultContainer>
);

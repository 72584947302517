import { useApi } from 'js/hooks/api';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { TUseApiProps } from 'js/hooks/api/useApi';
import { fillUrlParameters } from 'js/utils/common';
import { ApiMutationTypeEnum } from 'js/enums';

type TUseApiMutationProps = TUseApiProps & {
	key: string;
	type?: ApiMutationTypeEnum;
};

export type TUseApiDynamicParamsMutationData<Data extends object, DynamicParams extends object> = {
	data: Data;
	params: DynamicParams;
};

type TUseApiDynamicParamsMutation<Data extends object, DynamicParams extends object, Response> = (
	values: TUseApiDynamicParamsMutationData<Data, DynamicParams>,
) => Promise<AxiosResponse<Response>>;

export const useApiDynamicParamsMutation = <
	Data extends object,
	DynamicParams extends object,
	Response = Data,
	StaticParams extends object = object,
>(
	props: TUseApiMutationProps,
): TUseApiDynamicParamsMutation<Data, DynamicParams, Response> => {
	const { key, type = ApiMutationTypeEnum.POST, config } = props;
	const { params = {} } = config || {};
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (values) => {
		const [route, _params] = fillUrlParameters<Partial<StaticParams>>(key, { ...params, ...values.params });
		let promise: Promise<AxiosResponse<Response>>;

		// DELETE action do not need data
		if (type === ApiMutationTypeEnum.DELETE) {
			promise = api.delete<Data, AxiosResponse<Response>>(`/${route}`, {
				...config,
				params: _params,
			});
		} else {
			let instance: AxiosInstance['post'] | AxiosInstance['put'] | AxiosInstance['patch'];
			switch (type) {
				case ApiMutationTypeEnum.PUT:
					instance = api.put;
					break;
				case ApiMutationTypeEnum.PATCH:
					instance = api.patch;
					break;
				case ApiMutationTypeEnum.POST:
					instance = api.post;
					break;
				default:
					throw new TypeError(`Not supported api mutation type: ${type}`);
			}

			promise = instance<Data, AxiosResponse<Response>>(`/${route}`, values.data, {
				...config,
				params: _params,
			});
		}

		return promise;
	};
};

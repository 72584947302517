import { useCallback } from 'react';
import { IEntityPartner } from 'module/partners';
import { IEntityCustomer } from 'module/customers';
import { useAsyncApiPartnerCustomer } from 'module/customers/hooks/useAsyncApiCustomers';
import { logDebug } from 'js/utils/app';
import { useAppContext, useCommonContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { customersConfig } from 'module/customers/customersConfig';
import { STATUS_SUCCESS } from 'appConstants';

type TUsePartnerCustomerGuard = (
	partner: IEntityPartner,
	customer: IEntityCustomer,
) => Promise<false | IEntityCustomer>;

export const usePartnerCustomerGuard = (): TUsePartnerCustomerGuard => {
	const { t } = useTranslation();
	const asyncApiPartnerCustomer = useAsyncApiPartnerCustomer();
	const { confirmationModalRef } = useAppContext();
	const { createCustomerRef } = useCommonContext();

	return useCallback(
		async (partner, customer) => {
			const response = await asyncApiPartnerCustomer({
				partnerId: partner.id!,
				salesforceId: customer.id!,
			}).catch((error) => {
				logDebug(error);
			});

			// If partner is linked with a customer we want to update customer data in the order
			if (response?.data) {
				return response.data;
			}

			const createCustomerDecision = await confirmationModalRef.current?.show({
				title: t(licensesConfig.trPrefix('selectParty.customer.title')),
				messages: [t(licensesConfig.trPrefix('error.operation.customer.partnerIsNotBound.internal'))],
				submitButtonText: t(customersConfig.trPrefix('actions.add')),
			});

			if (createCustomerDecision !== STATUS_SUCCESS) {
				return false;
			}

			const customerResponse = await createCustomerRef.current?.show({
				partnerId: partner.id,
				initialValues: customer,
			});

			// If we created a new customer we want to update customer in the order
			if (customerResponse) {
				return customerResponse;
			}

			return false;
		},
		[asyncApiPartnerCustomer, confirmationModalRef, createCustomerRef, t],
	);
};

import { ICustomerForm, TEntityCustomerDeepKeys } from 'module/customers';
import { CustomerTypeEnum } from 'module/customers/enums';
import { isCustomerConsumer } from 'module/customers/utils/customerSelectors';

type TPerson = CustomerTypeEnum | 'LEGAL_PERSON_WITH_DISCOUNT';

const defaultRequiredFields: TEntityCustomerDeepKeys[] = [
	'firstName',
	'lastName',
	'email',
	'id',
	'billing.countryCode',
	'billing.stateCode',
	'contactType',
];

const additionalFields: Record<TPerson, TEntityCustomerDeepKeys[]> = {
	LEGAL_PERSON_WITH_DISCOUNT: [
		'companyName',
		'phone',
		'industry',
		'billing.street',
		'billing.postalCode',
		'billing.city',
		'website',
	],
	[CustomerTypeEnum.BUSINESS]: ['companyName'],
	[CustomerTypeEnum.CONSUMER]: ['billing.city'],
};

const requiredFields = Object.entries(additionalFields).reduce(
	(accumulator, [key, value]) => ({
		...accumulator,
		[key]: [...defaultRequiredFields, ...value],
	}),
	{},
) as Record<TPerson, TEntityCustomerDeepKeys[]>;

export const isRequired = (values: ICustomerForm, inputName: TEntityCustomerDeepKeys): boolean => {
	if (isCustomerConsumer(values, true)) {
		return requiredFields.NATURAL_PERSON.includes(inputName);
	}

	if (values.billing.discountType || values.requestedDiscountType) {
		return requiredFields.LEGAL_PERSON_WITH_DISCOUNT.includes(inputName);
	}

	return requiredFields.LEGAL_PERSON.includes(inputName);
};

const customerPrefix = '/customer';
const partnerCustomerPrefix = '/partner/:partnerId/customer';

export const customersRouteName = (options?: { withoutPrefix?: boolean }) => {
	const prefix = options?.withoutPrefix ? '' : customerPrefix;
	const partnerPrefix = options?.withoutPrefix ? '' : partnerCustomerPrefix;

	return {
		LIST: `${prefix}`,
		DETAIL: `${prefix}/:id`,
		UPDATE: `${prefix}/:id/update`,
		CREATE: `${prefix}/create`,
		PARTNER_DETAIL: `${partnerPrefix}/:id`,
		PARTNER_UPDATE: `${partnerPrefix}/:id/update`,
		PARTNER_CREATE: `${partnerPrefix}/create`,
	};
};

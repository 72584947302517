import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { AsyncModalLoading, TAsyncModalLoadingProps } from 'js/components/molecules/Modal/AsyncModalLoading';
import { TAsyncModalRef, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncConfirmationModal,
	TAsyncConfirmationModalProps,
} from 'js/components/molecules/Modal/AsyncConfirmationModal';
import { AsyncSuccessModal, TAsyncSuccessModalProps } from 'js/components/molecules/Modal/AsyncSuccessModal';
import { AsyncHelpModal, TAsyncHelpModalProps } from 'js/help/AsyncHelpModal';
import { useAppVersionGuard } from 'js/hooks/useAppVersionGuard';
import { TUsePrevLocationListener, usePrevLocationListener } from 'js/hooks/usePrevLocationListener';
import { AsyncApiErrorModal, TAsyncApiErrorModalProps } from 'js/components/molecules/Modal/AsyncApiErrorModal';
import { useSidebarCollapsed } from 'js/hooks/useSidebarCollapsed';
import { TUseStorageToggle } from 'js/hooks/useStorageToggle';
import type { IUILocaleContext } from '@avast/react-ui-components';
import { UILocaleContext } from '@avast/react-ui-components';
import { getUILocaleContext } from 'js/utils/locale';
import { GtmListener } from 'js/analytics/GtmListener';
import { TLocale } from 'types/config';
import { CustomEventEnum, useCustomEventListener } from 'js/events';

interface IAppContext {
	sidebarCollapsed: TUseStorageToggle;
	loadingModalRef: TAsyncModalRef<TAsyncModalLoadingProps>;
	confirmationModalRef: TAsyncModalRef<TAsyncConfirmationModalProps>;
	successModalRef: TAsyncModalRef<TAsyncSuccessModalProps>;
	apiErrorModalRef: TAsyncModalRef<TAsyncApiErrorModalProps>;
	helpModalRef: TAsyncModalRef<TAsyncHelpModalProps>;
	prevLocationListener: TUsePrevLocationListener;
	refreshUILocaleContext: (locale?: TLocale) => void;
}

const AppContext = createContext<IAppContext>({} as IAppContext);
AppContext.displayName = 'AppContext';

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const sidebarCollapsed = useSidebarCollapsed();
	const [uiLocaleContextValue, setUILocaleContextValue] = useState<IUILocaleContext>(getUILocaleContext());

	const loadingModalRef = useAsyncModalRef<TAsyncModalLoadingProps>();
	const confirmationModalRef = useAsyncModalRef<TAsyncConfirmationModalProps>();
	const successModalRef = useAsyncModalRef<TAsyncSuccessModalProps>();
	const apiErrorModalRef = useAsyncModalRef<TAsyncApiErrorModalProps>();
	const helpModalRef = useAsyncModalRef<TAsyncHelpModalProps>();

	const prevLocationListener = usePrevLocationListener();
	useAppVersionGuard();

	const closeModalsHandler = useCallback(() => {
		loadingModalRef.current?.hide();
		confirmationModalRef.current?.hide();
		successModalRef.current?.hide();
		apiErrorModalRef.current?.hide();
		helpModalRef.current?.hide();
	}, [loadingModalRef, confirmationModalRef, successModalRef, apiErrorModalRef, helpModalRef]);

	useCustomEventListener(CustomEventEnum.CLOSE_MODALS, closeModalsHandler);

	return (
		<UILocaleContext.Provider value={uiLocaleContextValue}>
			<GtmListener />
			<AppContext.Provider
				value={{
					sidebarCollapsed,
					prevLocationListener,
					loadingModalRef,
					confirmationModalRef,
					successModalRef,
					apiErrorModalRef,
					helpModalRef,
					refreshUILocaleContext(locale) {
						setUILocaleContextValue(getUILocaleContext(locale));
					},
				}}
			>
				{children}
				<AsyncModalLoading forwardedRef={loadingModalRef} />
				<AsyncConfirmationModal forwardedRef={confirmationModalRef} />
				<AsyncSuccessModal forwardedRef={successModalRef} />
				<AsyncApiErrorModal forwardedRef={apiErrorModalRef} />
				<AsyncHelpModal forwardedRef={helpModalRef} />
			</AppContext.Provider>
		</UILocaleContext.Provider>
	);
};

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { useApiCreateCustomer } from 'module/customers/hooks/useApiCustomers';
import { createCustomerApiError } from 'module/customers/utils/apiError';
import { useNavigate } from 'react-router-dom';
import { useApiErrorContext } from 'js/contexts';
import { useCustomerUrlParams, useGetCustomerLink } from 'module/customers/hooks';

export const PageCreate = (): ReactElement => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const navigate = useNavigate();
	const { setError } = useApiErrorContext();
	const { mutateAsync: createCustomer } = useApiCreateCustomer();
	const getCustomerLink = useGetCustomerLink();
	const { partnerId } = useCustomerUrlParams();

	return (
		<div className="section__content mt-3">
			<DynamicPageTitleItem text={t('page.create.title')} />
			<DynamicBreadcrumbsItem href={getCustomerLink.create(partnerId)}>{t('page.create.title')}</DynamicBreadcrumbsItem>

			<CustomerForm
				partnerId={partnerId}
				onSubmit={async (request, { setSubmitting }) => {
					const response = await createCustomer({
						data: request.customer,
						params: { partnerId: request.partnerId },
					}).catch((error) => {
						setError({ error, resolve: createCustomerApiError });
					});

					setSubmitting(false);
					if (response?.data) {
						toast.success(<Toast>{t('page.create.success')}</Toast>);
						navigate(getCustomerLink.detail(response.data.id, request.partnerId));
					}
				}}
			/>
		</div>
	);
};

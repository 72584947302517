import React, { PropsWithChildren, ReactElement, useCallback, useContext } from 'react';
import { IEntityCustomer } from 'module/customers';
import { TAsyncModalRef, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import {
	AsyncSelectCustomerModal,
	TAsyncSelectCustomerModalProps,
} from 'module/customers/components/AsyncSelectCustomerModal';
import {
	AsyncSelectDistributionPartnerModal,
	TAsyncSelectDistributionPartnerModalProps,
	useAsyncSelectDistributionPartnerRef,
} from 'module/distributionPartners/components/AsyncSelectDistributionPartnerModal';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { AsyncCreateCustomerModal, useAsyncCreateCustomerRef } from 'module/customers/components';
import { TAsyncCreateCustomerModalProps } from 'module/customers/components/AsyncCreateCustomerModal';
import { CustomEventEnum, useCustomEventListener } from 'js/events';

export interface ICommonContext {
	selectCustomerRef: TAsyncModalRef<TAsyncSelectCustomerModalProps, IEntityCustomer>;
	createCustomerRef: TAsyncModalRef<TAsyncCreateCustomerModalProps, IEntityCustomer>;
	selectDistributionPartnerRef: TAsyncModalRef<TAsyncSelectDistributionPartnerModalProps, IEntityDistributionPartner>;
}

const CommonContext = React.createContext<ICommonContext>({} as ICommonContext);
CommonContext.displayName = 'CommonContext';

export const useCommonContext = () => useContext(CommonContext);

export const CommonContextProvider = (props: PropsWithChildren): ReactElement => {
	const selectCustomerRef = useAsyncModalRef<TAsyncSelectCustomerModalProps, IEntityCustomer>();
	const createCustomerRef = useAsyncCreateCustomerRef();
	const selectDistributionPartnerRef = useAsyncSelectDistributionPartnerRef();

	const closeModalsHandler = useCallback(() => {
		selectCustomerRef.current?.hide();
		createCustomerRef.current?.hide();
		selectDistributionPartnerRef.current?.hide();
	}, [selectCustomerRef, createCustomerRef, selectDistributionPartnerRef]);

	useCustomEventListener(CustomEventEnum.CLOSE_MODALS, closeModalsHandler);

	return (
		<CommonContext.Provider value={{ selectCustomerRef, createCustomerRef, selectDistributionPartnerRef }}>
			{props.children}
			<AsyncSelectCustomerModal forwardedRef={selectCustomerRef} />
			<AsyncCreateCustomerModal forwardedRef={createCustomerRef} />
			<AsyncSelectDistributionPartnerModal forwardedRef={selectDistributionPartnerRef} />
		</CommonContext.Provider>
	);
};

import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { IEntityPartner } from 'module/partners';
import { TFormMessagesProps } from '@avast/react-ui-components';

type TUseCustomerPartnerValidator = { isValid: true } | { isValid: false; message: TFormMessagesProps['list'] };

export const useCustomerPartnerValidator = (partner?: IEntityPartner | null): TUseCustomerPartnerValidator => {
	const [t] = useTranslation(customersConfig.trNamespace);

	if (Boolean(partner)) {
		return { isValid: true };
	}

	return {
		isValid: false,
		message: [
			[
				t(`form:validator.mixed.required`, {
					path: t('common:entity.partner'),
					interpolation: { prefix: '${', suffix: '}' },
				}),
				'danger',
			],
		],
	};
};
